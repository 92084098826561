<template>
  <div class="footer">
    <div class="mdc-layout-grid">
      <footer class="mdc-layout-grid__inner">
        <div
          class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-8-tablet
            mdc-layout-grid__cell--span-4-desktop
          "
        >
          <a
            target="_blank"
            href="https://bubblemaps.io"
            class="footer__logo-container"
          >
            <SvgBubblemapsLogo class="footer__logo" />
            <SvgBubblemapsText class="footer__logotext" />
            <SvgOutwardsIcon class="footer__outward-icon" />
          </a>
          <div class="footer__subtitle">
            Innovative visuals for Blockchain data
          </div>
          <div class="footer__socials">
            <a target="_blank" href="https://twitter.com/bubblemaps">
              <SvgTwitterLogo class="footer__social" />
            </a>
            <a target="_blank" href="https://t.me/bubblemaps">
              <SvgTelegramLogo class="footer__social" />
            </a>
            <a target="_blank" href="https://discord.gg/bubblemaps">
              <SvgDiscordLogo class="footer__social footer__social--discord" />
            </a>
            <a target="_blank" href="https://www.instagram.com/bubblemaps.io/">
              <SvgInstagramLogo
                class="footer__social footer__social--instagram"
              />
            </a>
            <a target="_blank" href="https://www.youtube.com/c/Bubblemaps/">
              <SvgYoutubeLogo class="footer__social footer__social--youtube" />
            </a>
          </div>
          <div class="footer__policies --left">
            <div>
              Donations:
              <a
                target="_blank"
                href="https://etherscan.io/address/0x6710451bb62bEc1e546ec31bCb591aD61c6b19D5"
              >
                0x6710...19d5
              </a>
              ♥
            </div>
            <div>
              <a
                target="_blank"
                href="https://bubblemaps.notion.site/Bubblemaps-Terms-Conditions-6b2610c5432b48fc932cfa859ab33ecf"
              >
                Terms & Conditions
              </a>
              -
              <a
                target="_blank"
                href="https://bubblemaps.notion.site/Bubblemaps-Cookie-Preferences-1cb5871e572045c9be29fa26850a0d90"
              >
                Cookies
              </a>
              -
              <a
                target="_blank"
                href="https://bubblemaps.notion.site/Bubblemaps-Privacy-Policy-676d478607c449a0a57fed194e45c1f4"
              >
                Privacy
              </a>
            </div>
            <div class="footer__policies__copyright">
              © 2025 Bubblemaps SAS. All Rights Reserved.
            </div>
          </div>
        </div>
        <div
          class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-2
            mdc-layout-grid__cell--span-2-desktop
          "
        >
          <h5>About</h5>
          <a target="_blank" href="https://bubblemaps.io/">Main Website</a>
          <a target="_blank" href="https://bubblemaps.io/case-studies"
            >Case Studies</a
          >
          <a target="_blank" href="https://bubblemaps.io/get-premium"
            >Get Premium</a
          >
          <a target="_blank" href="https://bubblemaps.io/work-with-us">
            Integrate Bubblemaps
          </a>
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdj9s9DhslOWlgv1xaJRlOAAv-3lYOzR7W9IWpnv0v0b2EFog/viewform"
          >
            Get Listed
          </a>
          <a target="_blank" href="https://blog.bubblemaps.io/">Blog</a>
        </div>
        <div
          class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-2
            mdc-layout-grid__cell--span-2-desktop
          "
        >
          <h5>
            Supported<br />
            Chains
          </h5>
          <router-link to="/eth">Ethereum</router-link>
          <router-link to="/bsc">BNB Chain</router-link>
          <router-link to="/ftm">Fantom</router-link>
          <router-link to="/avax">Avalanche</router-link>
          <router-link to="/cro">Cronos</router-link>
          <router-link to="/arbi">Arbitrum</router-link>
          <router-link to="/poly">Polygon</router-link>
          <router-link to="/base">Base</router-link>
          <router-link to="/sol">Solana</router-link>
          <router-link to="/sonic">Sonic</router-link>
        </div>
        <div
          class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-2
            mdc-layout-grid__cell--span-2-desktop
          "
        >
          <h5>Integrations</h5>
          <a target="_blank" href="https://etherscan.io/">Etherscan</a>
          <a target="_blank" href="https://www.coingecko.com/">CoinGecko</a>
          <a target="_blank" href="https://pro.opensea.io/">OpenSea</a>
          <a target="_blank" href="https://dexscreener.com/">DEX Screener</a>
          <a target="_blank" href="https://www.geckoterminal.com/"
            >GeckoTerminal</a
          >
          <a target="_blank" href="https://pump.fun/">Pump.fun</a>
          <a target="_blank" href="https://dyorlabs.com/">DYOR Labs</a>
          <a target="_blank" href="https://www.dextools.io/app/">DEXTools</a>
        </div>
        <div
          class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-2
            mdc-layout-grid__cell--span-2-desktop
          "
        >
          <h5>Contact</h5>
          <a target="_blank" href="https://discord.gg/bubblemaps">Discord</a>
          <a target="_blank" href="https://t.me/Bubblemaps_BD">Telegram</a>
          <a target="_blank" href="mailto:api@bubblemaps.io">API & iFrames</a>
          <a target="_blank" href="mailto:press@bubblemaps.io">Press</a>
          <a target="_blank" href="https://discord.gg/bubblemaps">Support</a>
          <a target="_blank" href="mailto:contact@bubblemaps.io">Other</a>
        </div>
        <div
          class="
            mdc-layout-grid__cell mdc-layout-grid__cell--span-12
            footer__policies
            --bottom
          "
        >
          <div>
            Donations:
            <a
              target="_blank"
              href="https://etherscan.io/address/0x6710451bb62bEc1e546ec31bCb591aD61c6b19D5"
            >
              0x6710...19d5
            </a>
            ♥
          </div>
          <div>
            <a
              target="_blank"
              href="https://bubblemaps.notion.site/Bubblemaps-Terms-Conditions-6b2610c5432b48fc932cfa859ab33ecf"
            >
              Terms & Conditions
            </a>
            -
            <a
              target="_blank"
              href="https://bubblemaps.notion.site/Bubblemaps-Cookie-Preferences-1cb5871e572045c9be29fa26850a0d90"
            >
              Cookies
            </a>
            -
            <a
              target="_blank"
              href="https://bubblemaps.notion.site/Bubblemaps-Privacy-Policy-676d478607c449a0a57fed194e45c1f4"
            >
              Privacy
            </a>
          </div>
          <div class="footer__policies__copyright">
            © 2025 Bubblemaps SAS. All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
@import "assets/variables";

.footer {
  width: 100vw;
  background-color: $theme-dark-grey;
  color: $theme-light-grey;
  font-size: 0.8rem;
  padding-top: 60px;
  padding-bottom: 40px;
  a {
    margin-bottom: 8px;

    &:hover {
      color: $theme-pink;
    }
  }

  .mdc-layout-grid {
    padding-top: 0;
  }

  .mdc-layout-grid__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h5 {
    margin-top: 0;
    color: white;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1em;
  }
}

.footer__subtitle {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-size: 0.75rem;
}

.footer__socials {
  margin-top: 12px;
  display: flex;
  align-items: center;

  a {
    background-color: rgba($theme-pink, 0.3);
    transition: background-color 150ms linear;
    width: 35px;
    height: 35px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    .footer__social {
      height: 24px;
      fill: white;

      &.footer__social--instagram {
        height: 20px;
      }

      &.footer__social--youtube {
        height: 19px;
      }

      &.footer__social--discord {
        height: 18px;
        position: relative;
        top: -1px;
      }

      &.footer__social--email {
        height: 22px;
      }
    }

    &:hover {
      background-color: $theme-pink;
    }
  }
}

.footer__policies {
  margin-top: 10px;
  opacity: 0.7;
  font-size: 0.9em;

  &.--left {
    display: none;
  }

  .footer__policies__copyright {
    margin-top: 10px;
    font-size: 0.9em;
    opacity: 0.7;
  }
}

a.footer__logo-container {
  display: flex;
  align-items: center;
  padding: 15px;
  margin: -15px -15px 0 -15px;

  .footer__logo {
    height: 20px;
  }
  .footer__logotext {
    height: 20px;
    margin-left: 10px;
    position: relative;
    top: 1px;
    fill: white;
  }
  .footer__outward-icon {
    fill: rgba($theme-light-grey, 0.5);
    height: 15px;
    margin-left: 8px;
    transition: fill 200ms ease-in-out;
  }

  &:hover {
    .footer__outward-icon {
      fill: $theme-pink;
    }
  }
}

@media (min-width: $mdc-grid-desktop) {
  .footer {
    .mdc-layout-grid__cell {
      align-items: flex-start;
      text-align: left;
    }
  }
  .footer__policies {
    &.--left {
      display: initial;
    }

    &.--bottom {
      display: none;
    }
  }
}

@media (min-width: $mdc-desktop) {
  .footer {
    font-size: 0.9rem;
  }
}
</style>
